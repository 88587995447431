
import Button from '@mui/material/Button';
import Navbar from './components/Navbar/Navbar';


function App() {
  return (
    <div className="App">
      <Button variant="text">Text</Button>
      <Button color="primary" variant="contained">Contained</Button>
      <Button variant="outlined">Outlined</Button>
      <Navbar/>
    </div>
  );
}

export default App;
