import React from 'react';

const Navbar =()=> {
    return(
        <div>
            This is Navbar
        </div>
    )
}

export default Navbar